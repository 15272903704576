import React from "react";
import { Link } from "gatsby";

import { Text } from "../Core";

const Logo = ({
  title = "EgoalsBook",
  white,
  height,
  className = "",
  ...rest
}) => {
  const Title = white ? (
    <Text className="header-title-white" mb={0}>
      {title}
    </Text>
  ) : (
    <Text className="header-title-dark">{title}</Text>
  );
  return (
    <Link to="/" className={`${className}`} {...rest}>
      {Title}
    </Link>
  );
};

export default Logo;
